import { useWindowSize } from '@vueuse/core'

const BREACKPOINT_DESKTOP = 1024

export function useLayout() {
  const { width } = useWindowSize()
  const isDesktop = ref(false)
  if (process.client) {
    watch(
      () => width.value,
      (value) => {
        isDesktop.value = value >= BREACKPOINT_DESKTOP
      },
      { immediate: true },
    )
  }

  return {
    isDesktop,
  }
}
